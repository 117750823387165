/**
 * This script looks for elements with the `data-popup-delay` attribute and
 * starts a timer to show the element after the specified delay. If the user
 * interacts with the page by scrolling or clicking, the timer is reset.
 */
import { startTracking } from '@/track-interaction';
import { onDelegatedEvent } from '@/domEvents';

const init = () => {
	// Find delayed pop-up elements and initialize them
	document
		.querySelectorAll<HTMLElement>('[data-popup-delay]')
		.forEach((element) => {
			const delay = Number(element.getAttribute('data-popup-delay')) * 1000;
			const triggerEvents: string[] =
				element.dataset.popupTriggerEvents?.split(' ') ?? [];
			const abortController = new AbortController();

			let timer: ReturnType<typeof setTimeout> | undefined;

			console.log('init delayed pop-up', delay);

			const setTimer = () => {
				clearTimeout(timer);
				timer = setTimeout(showPopup, delay);
			};

			const resetTimer = () => {
				if (timer === undefined) return;
				setTimer();
			};

			const showPopup = () => {
				// Remove aria-hidden attribute to make the element visible
				element.removeAttribute('aria-hidden');
				cleanup();
				startTracking();
			};

			const cleanup = () => {
				clearTimeout(timer);
				abortController.abort();
			};

			if (triggerEvents.length == 0) {
				setTimer();
			} else {
				triggerEvents.forEach((eventName) => {
					document.addEventListener(eventName, setTimer, {
						signal: abortController.signal,
					});
				});
			}

			window.addEventListener('scroll', resetTimer, {
				signal: abortController.signal,
			});

			window.addEventListener('click', resetTimer, {
				signal: abortController.signal,
			});

			window.addEventListener('turbo:visit', cleanup, {
				signal: abortController.signal,
			});
		});

	// Add delegated event listener for closing pop-ups
	onDelegatedEvent('click', '[data-popup-close]', (event, el) => {
		const popup = el.closest('[data-popup-delay]');
		if (!(popup instanceof HTMLElement)) return;

		popup.setAttribute('aria-hidden', 'true');
		const popupId = popup.dataset.popupId;

		// If the popup has an ID, store a cookie to prevent it from showing again
		if (popupId) {
			const cookieName = `popup-${popupId}-dismissed`;
			document.cookie = `${cookieName}=true; path=/; max-age=31536000`;
		}
	});
};

document.addEventListener('turbo:load', init);
