type SyncFunction<T extends unknown[], R> = (...args: T) => R;
type AsyncFunction<T extends unknown[], R> = (...args: T) => Promise<R>;

/**
 * Wraps an async function so that it can only be called once.
 * Even if the first call is still pending, subsequent calls will return the
 * same promise.
 * A limitation of this implementation is that it does not handle being called
 * with different arguments. If resulting function is called with different
 * arguments, it will still return the result of the first call.
 */
export function onceAsync<T extends unknown[], R>(
	wrapped: AsyncFunction<T, R>
): AsyncFunction<T, R> {
	let result: R | undefined;
	let pending: Promise<R>;
	let isPending = false;
	return async function (...args: T): Promise<R> {
		if (result !== undefined) return result;

		if (isPending) {
			return pending;
		}

		isPending = true;
		pending = wrapped(...args);
		try {
			result = await pending;
		} catch (e) {
			throw e;
		} finally {
			isPending = false;
		}
		return result;
	};
}

/**
 * Wraps a function so that it can only be called once.
 * Subsequent calls will return the result of the first call.
 * A limitation of this implementation is that it does not handle being called
 * with different arguments. If resulting function is called with different
 * arguments, it will still return the result of the first call.
 */
export function once<T extends unknown[], R>(
	wrapped: SyncFunction<T, R>
): SyncFunction<T, R> {
	let result: R;
	let alreadyCalled = false;
	return function (...args: T): R {
		if (alreadyCalled) {
			return result;
		}

		result = wrapped(...args);
		alreadyCalled = true;
		return result;
	};
}
